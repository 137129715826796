/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import SectionHeader from '../SectionHeader/SectionHeader';

const AboutPage = () => {
  return (
    <Box component="section" className="about-page">
      <Container maxWidth="lg">
        <SectionHeader>
          О нас
        </SectionHeader>
        <Box>
          <Typography component="h3" marginBottom={2}>
            "Вкусная Еда" – разнообразная кухня с Доставкой в Нижнем Новгороде
          </Typography>
          <Box component="p">
            Добро пожаловать в "Вкусная Еда" - вашего надежного партнера в мире вкусной кухни с удобной доставкой прямо к вашему порогу в Нижнем Новгороде.
          </Box>
          <Typography component="b">Наша История:</Typography>
          <Box component="p">
            "Вкусная Еда" была основана с любовью к традиционным рецептам кухни рахных народов и стремлению подарить каждому клиенту незабываемый опыт.
            Наша команда поваров тщательно отбирает лучшие ингредиенты, чтобы создавать блюда, полные вкуса и тепла домашней кухни.
          </Box>
          <Typography component="b">Что Мы Предлагаем:</Typography>
          <Box component="p">
            Аутентичные Блюда: Мы гордимся предлагаемыми блюдами, которые сохраняют дух кухни разных народов, от мяса по-французки до борща, от пирогов до картошки по-деревенски.
            Свежие Продукты: Каждое блюдо готовится с использованием только свежих и высококачественных продуктов, чтобы ваш обед был не только вкусным, но и питательным.
            Доставка с Любовью: Наша служба доставки обеспечивает своевременную и бережную передачу вашего заказа. Мы заботимся о том, чтобы каждый заказ достиг вас таким же вкусным, как и в ресторане.
          </Box>
          <Typography component="b">Наши Ценности:</Typography>
          <Box component="p">
            Традиции: Мы ценим и продолжаем традиции кухни народов, передавая вам атмосферу уюта и вкуса.
            Клиентоориентированность: Ваше удовлетворение - наш главный приоритет. Мы стремимся к полному соответствию вашим ожиданиям и готовы принять ваши пожелания.
            Открытость: Мы открыты для обратной связи. Если у вас есть вопросы, предложения или замечания, пожалуйста, свяжитесь с нами по телефону.
          </Box>
          <Box component="p">
            Спасибо, что выбрали "Вкусная Еда"! Доверьтесь нам, чтобы каждый ваш обед был настоящим кулинарным путешествием по вкусам кухни народов.
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutPage;
